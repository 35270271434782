import {
  Text,
  Tabs,
  Avatar,
  Flex,
  Box,
  Card,
  Heading,
  Strong,
  Grid,
  Separator,
  Link,
  Em,
  Blockquote,
} from "@radix-ui/themes";
import Lottie from "react-lottie-player";
import lottieJSON1 from "./laptop-custom.json";
import lottieJSON2 from "./mail.json";

export function App() {
  return (
    <Tabs.Root defaultValue="home">
      <Card size="3">
        <Flex gap="5">
          <Avatar src="simianlogo.svg" fallback="A" size="4" />
          <Tabs.List highContrast>
            <Tabs.Trigger value="home">
              <Text size="6" color="amber">
                <Strong>Home</Strong>
              </Text>
            </Tabs.Trigger>
            <Tabs.Trigger value="about">
              <Text size="6" color="amber">
                <Strong>About</Strong>
              </Text>
            </Tabs.Trigger>
            <Tabs.Trigger value="contact">
              <Text size="6" color="amber">
                <Strong>Contact</Strong>
              </Text>
            </Tabs.Trigger>
          </Tabs.List>
        </Flex>
      </Card>

      <Box pt="3">
        <Tabs.Content value="home">
          <Heading
            as="h1"
            align="center"
            size="8"
            color="amber"
            style={{ padding: "1em" }}
          >
            Simple modern web security.
          </Heading>
          <Box width="70%" m="auto" style={{ wordBreak: "initial" }}>
            <Text size="5">
              Are you sick of pentesting companies reporting a bunch of nothing
              burgers? Using the same automated tools that you could run
              yourself? We hate it too. Choose{" "}
              <Strong style={{ color: "#FFC53D" }}>Simian Security</Strong> and
              get the <Em>real</Em> vulnerabilities in your inbox that
              successful bug bounty programs are getting.
            </Text>
          </Box>
          <Flex gap="5" width="70%" m="auto" p="2em">
            <Box
              width="40%"
              minWidth="40%"
              style={{
                textAlign: "center",
                wordBreak: "keep-all",
                overflowWrap: "normal",
                whiteSpace: "no-wrap",
              }}
            >
              <Heading
                as="h2"
                size="7"
                m="1em"
                color="red"
                style={{
                  textAlign: "center",
                  wordBreak: "keep-all",
                  overflowWrap: "normal",
                  whiteSpace: "no-wrap",
                }}
              >
                Proven Results.
              </Heading>
              <Text size="5">
                {" "}
                We've found{" "}
                <Text color="red">
                  <Strong>High</Strong>
                </Text>{" "}
                and{" "}
                <Text color="red">
                  <Strong>Critical</Strong>
                </Text>{" "}
                severity issues on some of the world's largest companies.
              </Text>
              <Grid columns="3" rows="2" p="2em" gap="9" width="100%">
                <img
                  src="meta-icon.svg"
                  alt="Meta Logo"
                  style={{
                    margin: "auto",
                    width: "100%",
                    height: "100%",
                    borderRadius: "var(--radius-2)",
                  }}
                />
                <img
                  src="grammarly-1.svg"
                  alt="Grammarly Logo"
                  style={{
                    margin: "auto",
                    width: "100%",
                    height: "100%",
                    borderRadius: "var(--radius-2)",
                  }}
                />
                <img
                  src="zoom-app.svg"
                  alt="Zoom Logo"
                  style={{
                    margin: "auto",
                    width: "100%",
                    height: "100%",
                    borderRadius: "var(--radius-2)",
                  }}
                />
                <img
                  src="cat-1.svg"
                  alt="CAT Logo"
                  style={{
                    margin: "auto",
                    width: "100%",
                    height: "100%",
                    borderRadius: "var(--radius-2)",
                  }}
                />
                <img
                  src="rockstar-games.svg"
                  alt="Rockstar Logo"
                  style={{
                    margin: "auto",
                    width: "100%",
                    height: "100%",
                    borderRadius: "var(--radius-2)",
                  }}
                />
                <img
                  src="digitalocean-icon.svg"
                  alt="DigitalOcean Logo"
                  style={{
                    margin: "auto",
                    width: "100%",
                    height: "100%",
                    borderRadius: "var(--radius-2)",
                  }}
                />
              </Grid>
            </Box>
            <Lottie
              loop
              animationData={lottieJSON1}
              play
              style={{ width: 400, height: 400, margin: "auto" }}
            />
          </Flex>
        </Tabs.Content>

        <Tabs.Content value="about">
          <Box width={{lg:"60%", initial:"100%", xs:"80%"}} m="auto" p="2em">
            <Heading
              as="h1"
              size="7"
              color="amber"
              style={{ textAlign: "center", padding: "20px" }}
            >
              Who are we?
            </Heading>
            <Box m="auto" p="2em" style={{ textAlign: "center" }}>
              <img
                src="https://takeshi.ie/images/me.jpg"
                style={{ width: "40%", borderRadius: "1em" }}
                alt="Ciarán Portrait"
              />
            </Box>
            
            <Text size="5" style={{ wordBreak: "keep-all" }}>
              <Strong style={{ color: "#FFC53D" }}>Simian Security</Strong> is
              operated by{" "}
              <Strong style={{ color: "#FFC53D" }}>Ciarán Cotter</Strong> - one
              of Ireland's leading bug bounty hunters with over{" "}
              <Link
                color="indigo"
                underline="always"
                href="https://hackerone.com/monke?type=user"
              >
                140 paid findings
              </Link>{" "}
              across dozens of the world's largest companies, in environments
              that are already well-hardened by industry pentesting and internal
              security assessments. Previously a SaaS Security Researcher at
              AppOmni, Ciarán's research in SaaS security has been featured in{" "}
              <Link
                color="indigo"
                underline="always"
                href="https://www.forbes.com/sites/petersuciu/2023/11/29/security-remains-a-real-concern-with-real-time-communication-tools/?sh=41b0ccae15c9"
              >
                Forbes
              </Link>
              ,{" "}
              <Link
                color="indigo"
                underline="always"
                href="https://www.techradar.com/pro/security/zoom-fixes-significant-security-flaw-that-could-have-allowed-hackers-to-hijack-your-meetings#:~:text=Zoom%20Rooms%20is%20a%20system,licenses%20for%20Meetings%20and%20Whiteboards."
              >
                TechRadar
              </Link>
              ,{" "}
              <Link
                color="indigo"
                underline="always"
                href="https://www.scmagazine.com/news/zoom-flaw-enabled-hijacking-of-accounts-with-access-to-meetings-team-chat"
              >
                SC Magazine
              </Link>{" "}
              and more. Ciarán also runs{" "}
              <Link color="indigo" underline="always" href="https://monke.ie">
                MonkeHacks
              </Link>
              , a web security newsletter.
            </Text>
          </Box>
          <Separator my="6" size="4" />
          <Box pb="9">
            <Heading
              as="h1"
              size="7"
              color="amber"
              style={{ textAlign: "center", paddingBottom: "1em" }}
            >
              Testaments
            </Heading>
            <Grid
              columns={{lg:"2", initial:"1"}}
              gap="3"
              rows={{lg:"1", initial:"2"}}
              width="75%"
              style={{ margin: "auto" }}
            >
              <Card size="2" style={{ width: "100%" }}>
                <Grid columns="1" gap="1" rows="3">
                  <Box m="auto" style={{ textAlign: "center" }}>
                    <Heading>Michael Ness</Heading>
                    <Strong>CEO, Overcast Security</Strong>
                  </Box>
                  <Avatar
                    src="https://assets-global.website-files.com/62ea90a2c8ad8df533044fa9/62f238e407887bef4cca60ed_ceo-p-500.webp"
                    fallback="M"
                    size="9"
                    m="auto"
                  />
                  <Box m="auto">
                    <Blockquote m="5">
                      "I've worked with Ciarán for several years. We've worked
                      on numerous projects together, and he's always been a
                      pleasure to work with. He has excellent communication
                      skills and has an eye for detail. I wouldn't hesitate to
                      recommend him to anyone."
                    </Blockquote>
                  </Box>
                </Grid>
              </Card>
              <Card size="2" style={{ width: "100%" }}>
                <Grid columns="1" gap="1" rows="3">
                  <Box m="auto" style={{ textAlign: "center" }}>
                    <Heading>Joseph Thacker</Heading>
                    <Strong>Principal AI Engineer, AppOmni</Strong>
                  </Box>
                  <Avatar
                    src="https://pbs.twimg.com/profile_images/1696254262303436800/cnIo7SO0_400x400.jpg"
                    fallback="M"
                    size="9"
                    m="auto"
                  />
                  <Box m="auto">
                    <Blockquote size="3">
                      "Ciarán is one of the most talented hackers I know. He's
                      got a wide range of skills and natural talent to do
                      top-tier thorough testing, but he's also got the curiosity
                      and drive required to find novel vulnerabilities in
                      hardened systems."
                    </Blockquote>
                  </Box>
                </Grid>
              </Card>
            </Grid>
          </Box>
        </Tabs.Content>

        <Tabs.Content value="contact">
          <Box width="75%" m="auto" p="2em">
            <Heading
              as="h1"
              size="7"
              color="amber"
              style={{ textAlign: "center", padding: "20px" }}
            >
              Contact Us
            </Heading>
            <Box
              style={{
                textAlign: "left",
                wordBreak: "keep-all",
                overflowWrap: "normal",
                whiteSpace: "no-wrap",
              }}
            >
              <Text size="5">
                Reach out to{" "}
                <Strong>
                  <Link
                    href="mailto:enquiries@simiansecurity.com"
                    underline="always"
                  >
                    enquiries@simiansecurity.com
                  </Link>
                </Strong>{" "}
                to discuss your needs and enquire about pricing.
              </Text>
            </Box>
          </Box>
        </Tabs.Content>
      </Box>
    </Tabs.Root>
  );
}
